import React from "react";

const Search = () => {

  const [text, setText] = React.useState("");
  const ref = React.useRef(null);
  const [pressedKeys, setPressedKeys] = React.useState([]);
  const [results, setResults] = React.useState([]);

  React.useEffect(() => setResults(
    text.length === 0 || !window.__FLEXSEARCH__.en.index ? [] :
      window.__FLEXSEARCH__.en.store.filter(
        node => Object.values(window.__FLEXSEARCH__.en.index).map(
          index => index.values.search(text)
        ).flat().includes(node.id)
      ).map(n => n.node)
  ), [text]);

  React.useEffect(() => {

    const handleKey = (e) => {
      
      if (e.type === 'keydown') {
        setPressedKeys( pressedKeys =>  [...pressedKeys, e.keyCode])
      } else {
        setPressedKeys( pressedKeys => pressedKeys.filter(k=>k!==e.keyCode))
      }
    }; 
    
    document.addEventListener("keydown", handleKey);
    document.addEventListener("keyup", handleKey);
    return () => {
      document.removeEventListener("keydown", handleKey);
      document.removeEventListener("keyup", handleKey);
    }

  },[]);

  React.useEffect(()=>{
    if (pressedKeys.includes(17) && pressedKeys.includes(191)){
      ref.current.focus()
    }
  },[pressedKeys]);

  const icons = {
    arduino:"arduino",
    trainings:"award",
    docs:"book"
  }

  return (
    <form className="bert-search">
      <div className="form-group">
        <input
          ref={ref}
          onBlur={() => setTimeout(()=>setResults([]), 100)}
          onFocus={() => setText(text)}
          placeholder="Search..."
          type="search"
          className="form-control"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </div>
      <div className="search-results list-group">
        {
          results.map((node,idx) =>
            <a key={idx} href={`/${node.path}`} className='list-group-item list-group-item-action'>
              <div className="row">
                <div className="col-md-2">
                  <i className={`bi-${icons[node.path.split('/')[0]]}`} />
                </div>
                <div className="col-md-4">
                  <strong>{node.title}</strong>
                </div>
                <div className="col-md-6">
                  <div className="text-muted">
                    {node.description}
                  </div>
                </div>
              </div>
            </a>
          )
        }
      </div>
    </form>
  )
}

export default Search;