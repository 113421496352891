import React from "react";
import bertIcon from '../images/icon.png';

const Loading = ({children}) =>
  <span className="text-center p-1">
    <div className="spinner-border" role="status">
      <img src={bertIcon} height="20" className="bg-transparent mb-1"/>
    </div>
    <span className="m-3">{children}</span>
  </span>

export default Loading;