import React from 'react';
import Root from './src/templates/root';

import './src/styles/icons/style.css';
import './src/styles/search.css';
import './src/styles/syntax-highlighting.css';

import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import "bootstrap/dist/css/bootstrap.min.css";
import "github-markdown-css/github-markdown.css";

export const wrapRootElement = ({element, props}) => {
  return <Root {...props}>{element}</Root>
}