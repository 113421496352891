import React from 'react';
import Navigation from '../components/navigation';
import Loading from '../components/loading';

import googleIcon from "../images/google.png"
import bertIcon from "../images/icon.png"

import '../styles/root.css'

export default function RootLayout({ children }) {

  const [user, setUser] = React.useState();
  const [darkMode, setDarkMode] = React.useState(false);

  React.useEffect(() => {
    const serverUrl = process.env.GATSBY_SERVER_URL || (window.location.hostname === 'thebetalab.org' ? 'https://api.thebetalab.org' : 'https://bert-api-testing-zcgw6ooqia-uc.a.run.app')
    fetch(`${ serverUrl }/auth/user`, {
      credentials: "include"
    })
      .then(resp => resp.json())
      .then(result => setUser(result) )
  }, []);

  React.useEffect(()=>setDarkMode(window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches),[]);

  const LoginButton = ({url}) =>
    <div className='container-fluid'>
      <div className='row vh-100'>
        <div className='col-12 d-flex align-items-center justify-content-center'>
          <a type='button' className='btn btn-outline-secondary' href={url}>
            <div className='row'>
              <div className='col mt-auto mb-auto'>
                <img src={bertIcon} height={40}/> ↔ <img src={googleIcon} height={40}/>
              </div>
              <div className='col mt-auto mb-auto'>
                Login with your Google Account
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>

  return (
    <div className={`bert-wrapper`} data-bs-theme={ darkMode ? "dark" : "light" }>
      {
        !user ? <Loading>Loading User Data...</Loading> :
        user.authorizationUrl ? <LoginButton url={user.authorizationUrl}/> :
          <div className='container-xxl'>
            <Navigation user={user.error ? null : user} />
            {children}
          </div>
      }
    </div>
  );
};