import React from 'react';
import { Link, navigate } from 'gatsby';

import '../styles/navigation.css';
import icon from '../images/icon.png'
import Search from './search';

const Navigation = ({ user, data }) => {

  return ( !user ? null :
    <nav className="navbar navbar-expand-lg">

        <a href="/" className='navbar-brand'>
          <img className='image' height="40" src={icon} /> <span className='fs-4'>βert</span>      
        </a>

        <button className="btn d-lg-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggle" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <i className="bi-list"></i>
        </button>

        <div className="collapse navbar-collapse" id="navbarToggle">

          <ul className="navbar-nav me-auto mb-2">

            <li className='nav-item'>
            </li>

            <li className="nav-item">
              <a href="/" className='nav-link'> <i className='bi-house-door-fill'></i> Home</a>
            </li>

            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarGuidesDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i className='bi-journal'></i> Guides
              </a>
              <ul className="dropdown-menu p-1" aria-labelledby="navbarGuidesDropdown">
                <li className='p-1'>
                  <Link className="nav-link dropdown-item" to="/docs/introduction/starting-a-project">
                    <div className='row'>
                      <div className='col-2'>
                        <i className='bert-icons-bert'></i>
                      </div>
                      <div className='col-10'>
                        Bert
                      </div>
                    </div>
                  </Link>
                </li>
                <li className='p-1'>
                  <Link className="nav-link dropdown-item" to="/arduino/getting-started/introduction">
                    <div className='row'>
                      <div className='col-2'>
                        <i className='bert-icons-arduino'></i>
                      </div>
                      <div className='col-10'>
                        Arduino
                      </div>
                    </div>
                  </Link></li>
              </ul>
            </li>

            <li className="nav-item">
              <a className='nav-link' href='/tools'><i className='bi-tools'></i> Tools</a>
            </li>

            <li className="nav-item">
              <a className='nav-link' href='/trainings'><i className='bi-card-checklist'></i> Trainings</a>
            </li>

          </ul>

        </div>

        <Search data={data}/>

        <div className="btn-group">
          <button type="button" id="userButton" className="btn dropdown-toggle btn-small" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <img src={user.picture} height="30" /> {user.name}
          </button>
          <div className="dropdown-menu dropdown-menu-right">
          {
              !user.admin ? null :
              <button onClick={() => navigate('/admin')} className="dropdown-item" type="button"><i className='bi-gear'></i> Admin</button>
            }
            <button onClick={() => navigate('/logout')} className="dropdown-item" type="button"><i className='bi-door-open'></i> Logout</button>

          </div>
        </div>

    </nav>
  )
}

export default Navigation;